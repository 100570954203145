import React from 'react'
import SearchListTemplate from '../../components/templates/SearchListTemplate'

const PurchaseAds = () => {
  const title = 'Trouver Mon Local Pro - Nos Annonces à acheter'
  const description = 'Tous les locaux professionnels du 2 au 5 pièces à acheter.'

  return (
    <SearchListTemplate
      title={title}
      description={description} />
  )
}

export default PurchaseAds
